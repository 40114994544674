import { createRoot } from "react-dom/client";
import "./index.css";
import { Root } from "./Root";
import { Provider } from "react-redux";
import { store } from "./app/store";

createRoot(document.getElementById("root") as HTMLElement).render(
  <Provider store={store}>
    <Root />
  </Provider>
);
